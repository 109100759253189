<template>
  <div class="text-center text-primary my-2 load-content-card">
    <b-spinner style="width: 3rem; height: 3rem" class="align-middle mr-2"></b-spinner>
    <strong> {{ $t('loading') }}</strong>
  </div>
</template>

<script>
import authApi from "@/repository/authApi";
import CryptoJS from "crypto-js";
import functionsCommon from "@/common/functions";

export default {
  name: "Loading",
  data() {
    return {
      package_id: null,
      custom_package: null,
    }
  },
  created() {
    const ref_id = this.$route.query.ref_id
    const package_id = this.$route.query.package_id
    const custom_package = this.$route.query.price
    const browser = this.$route.query.browser
    const token = this.$route.query.access_token
    const channel = this.$route.query.channel
    const tracking_channel = this.$route.query.tracking_channel
    this.setCookie('CHANNEL', channel, 1)
    if (browser) {
      this.setCookie('BROWSER', browser, 1)
    } else {
      this.removeCookie('BROWSER')
    }
    if (ref_id) {
      window.location.href = `/Register?ref_id=${ref_id}`;
    } else if (package_id || tracking_channel) {
      this.setCookie('TRACKING_CHANNEL', tracking_channel, 30)
      this.setCookie('P_ID', package_id, 1)
      window.location.href = `/Subscriptions/Package?package_id=${package_id}&go_to=checkout` + (channel ? `&channel=${channel}` : '') + (tracking_channel ? `&tracking_channel=${tracking_channel}` : '');
    } else if (custom_package) {
      this.setCookie('CUSTOM_PRICE', custom_package, 1)
      window.location.href = `/Subscriptions/Package?price=${custom_package}` + (channel ? `&channel=${channel}` : '');
    } else if (token) {
      const data = {
        strategy: "jwt",
        accessToken: token
      }
      this.login(data)
    } else {
      window.location.href = "/Login";
    }
  },

  methods: {
    login(data) {
      this.package_id = this.getCookie('P_ID')
      this.custom_package = this.getCookie('CUSTOM_PRICE')
      authApi.login(data).then((response) => {
        if (response.codeSYS === '001') {
          this.$store.dispatch('profile/setUserInfo', response)
          const profile = this.$store.getters["profile/getUserInfo"]
          this.$store.dispatch('profile/setName', profile?.first_name + " " + profile?.last_name)
          this.$store.dispatch('profile/setCredit', profile.sms_unit)
          this.$store.dispatch('profile/setURLTracking', profile.url_tracking)
          this.$i18n.locale = profile.locale
          if (!_.isNil(this.package_id) || !_.isEmpty(this.package_id)) {
            window.location.href = `/Subscriptions/Package?package_id=${this.package_id}&go_to=checkout`;
          } else if (!_.isNil(this.custom_package) || !_.isEmpty(this.custom_package)) {
            window.location.href = `/Subscriptions/Package?price=${this.custom_package}`;
          } else {
            window.location.href = "/";
          }
        } else {
          window.location.href = "/Login";
        }
      })
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "==" + (value || "") + expires + "; path=/";
    },
    getCookie(c_name) {
      return functionsCommon.getCookie(c_name)
    },
    removeCookie(name) {
      return functionsCommon.deleteCookie(name)
    }
  }
}
</script>

<style scoped>
.load-content-card {
  display: block;
  max-width: 1300px;
  padding: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>